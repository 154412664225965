// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import Rails from "@rails/ujs";
import jquery from "jquery";
// import * as ActionCable from '@rails/actioncable';
// import * as ActiveStorage from "@rails/activestorage";
import "./controllers";

// ActionCable.start();
Rails.start();
window.Rails = Rails;
window.jQuery = jquery;
window.$ = jquery;
// ActiveStorage.start();

import("chartkick/chart.js");
import("./bootstrap");
import("./flash");
// import "./cable";

import {config, library, dom} from '@fortawesome/fontawesome-svg-core'
import {faUser as fasFaUser} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt as fasFaTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {faSignOutAlt as fasFaSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {faEye as fasFaEye} from '@fortawesome/free-solid-svg-icons'
import {faPlus as fasFaPlus} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight as fasFaChevronRight} from '@fortawesome/free-solid-svg-icons'
import {faChevronLeft as fasFaChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faSpinner as fasFaSpinner} from '@fortawesome/free-solid-svg-icons'
import {faUsers as fasFaUsers} from '@fortawesome/free-solid-svg-icons'
import {faCog as fasFaCog} from '@fortawesome/free-solid-svg-icons'
import {faSearch as fasFaSearch} from '@fortawesome/free-solid-svg-icons'
import {faHospitalUser as fasHospitalUser} from '@fortawesome/free-solid-svg-icons'
import {faTimesCircle as fasTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {faTimes as fasTimes} from '@fortawesome/free-solid-svg-icons'
import {faEdit as fasFaEdit} from '@fortawesome/free-solid-svg-icons'
import {faLink as fasFaLink} from '@fortawesome/free-solid-svg-icons'
import {faPenToSquare as fasFaPenToSquare} from '@fortawesome/free-solid-svg-icons'
import {faBars as fasFaBars} from '@fortawesome/free-solid-svg-icons'
import {faClock as fasFaClock} from '@fortawesome/free-solid-svg-icons'
import {faEllipsisV as fasFaEllipsisV} from '@fortawesome/free-solid-svg-icons'
import {faEllipsis as fasFaEllipsis} from '@fortawesome/free-solid-svg-icons'
import {faHospital as fasFaHospital} from '@fortawesome/free-solid-svg-icons'


config.mutateApproach = 'sync'
library.add(fasFaUser, fasFaTrashAlt, fasFaPlus, fasFaSignOutAlt, fasFaChevronRight, fasFaChevronLeft, fasFaEye,
    fasFaSpinner, fasFaUsers, fasFaCog, fasFaSearch, fasHospitalUser, fasTimesCircle, fasTimes, fasFaEdit, fasFaLink,
    fasFaPenToSquare, fasFaBars, fasFaClock, fasFaEllipsisV, fasFaEllipsis, fasFaHospital)
dom.watch()
